<template>
	<section>
		<b-card class="px-table-0 pb-2">
			<b-row class="align-center">
	            <b-col lg="6" style="padding-left: 33px ">
	                <h2 class="mb-2"><strong>Sub Topic</strong></h2>
	            </b-col>
	            <b-col lg-6>
	            	<div class="text-right mb-2 pr-2">

	            		<template v-if="(userData && permission.edit)">

		                    <b-button type="button" variant="basic" class="btn-df size-18 black" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px " @click="cancelAction">Cancel</b-button>
		                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
		                        Save
		                    </b-button>
		                    <!-- <b-button type="button" variant="primary" class="btn-df size-18 black" v-else-if="isEditing" @click="editAction">
	                            Edit
	                        </b-button>    -->

	                    </template>
	                    <template v-else>
	                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
	                            Edit
	                        </b-button>  
	                    </template>
	                </div>
	            </b-col>
	        </b-row>	
	        <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>	
	        <validation-observer
		        ref="form_rel"
		        #default="{invalid}" >
		        <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
		        	<div class="frm-basic size-16 black-child d-block">
	                  	<b-row style="width: 100%; margin: 0;">   
	                      	<b-col lg="12" class="px-0">             
	                          	<b-form-group label="Sub-Topic Name" label-cols-lg="2" class="star-required">
	                          		<validation-provider
		                              	#default="{ errors }"
		                              	name="Sub-Topic Name"
		                              	rules="required"                                  
		                            >    
		                                <b-form-input
		                                	v-model="detail.name"
		                                  	placeholder="Name"
		                                  	:formatter="length100"
		                                ></b-form-input>
		                            	<small class="text-danger">{{ errors[0] }}</small>
					                </validation-provider>
	                          	</b-form-group>
	                      	</b-col>			                          	
	                      	<b-col lg="12" class="px-0">
	                            <b-form-group label="Description" label-cols-lg="2" class="star-required">
	                            	<validation-provider
		                              	#default="{ errors }"
		                              	name="Description"
		                              	rules="required"                                  
		                            >   
		                            	<b-form-textarea
		                            		v-model="detail.description"
		                                    placeholder="Description"
		                                    rows="4"
		                                    :formatter="length1000"
											style="padding-top: 12px !important"
		                                ></b-form-textarea>
		                                <small class="text-danger">{{ errors[0] }}</small>
					                </validation-provider>
	                            </b-form-group>
	                      	</b-col> 
	                  	</b-row>
	                </div>
		        </b-form> 
		    </validation-observer>
		</b-card>
	</section>
</template>
<script>

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	detail: [],
				permission: [],
		    	isEditing: true,
	            isDisabled: true,
            }
		},		
		created(){
			this.featchItem()
			this.checkPermission("Product")
		},
		methods: {
			length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
			checkPermission(namePage){
				var action = [];
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
					
				})
				this.permission = action 
			},
			featchItem(){
	            this.$store
	            .dispatch('project/detailTopic', {
	              	id: this.$route.params.topic_id  
	            })
	            .then(response => {
	            	this.detail = response.data.data
	              	// console.log(response.data.data)
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
	        editAction(){
	        	this.isDisabled = false
          		this.isEditing = false
	        },
	        cancelAction(){
	          	this.$router.push({ path: `/admin/itees-product/${this.$route.params.id}` })
	        },
	        saveAction(){
	        	this.$refs.form_rel.validate().then(success => {
                	if(success){
                		let fields = {
							name: this.detail.name,
							description: this.detail.description,
						}
						// console.log('data: ', fields)
						this.$store
						.dispatch('project/editTopic', 
		                {
		                    id: this.$route.params.topic_id,
		                    data: fields
		                })
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.detail.name + ' Updated!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.$router.push({ path: `/admin/itees-product/${this.$route.params.id}` })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
						})
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
                })
	        }
		},
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}

</style>