<template>
	<section>
		<b-row class="mb-2 align-center" style="justify-content: space-between">
		    <b-col>
				<Breadcrumb :labels="labels" :name_sublabel="name_sublabel" />				
		    </b-col>	
		    <b-col v-if="(this.$route.name == 'itees-product-detail-blank' && tabIndexProduct == 1) || (tabIndexProduct == 2)">
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					            placeholder="Search"
					            @input="searchValue"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>
		    </b-col>	    
		</b-row>	
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom" v-model="tabIndexProduct">
			            <b-tab title="Basic Info" class="overview-tab">	
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20 mt-10" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6" style="padding-left: 33px ">
							                <h2 class="mb-2"><strong>Basic Info</strong></h2>
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">

							            		<template v-if="(userData && permission.edit)">
								                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" v-if="!isEditing" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction" v-if="!isEditing">
								                        Save
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" v-else-if="isEditing" @click="editAction">
								                        Edit
								                    </b-button>
								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Edit
								                    </b-button>
								                </template>

							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="12" class="px-0">             
					                            <b-form-group label="Product Name" label-cols-lg="2" class="star-required input-group-label">    
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Product Name"
						                              	rules="required"                
						                            >                        
					                                    <b-form-input
					                                    	v-model="detail.name"
					                                      	placeholder="Name"
					                                      	:disabled="isDisabled"
					                                      	:formatter="length100"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>			                          	
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Description" label-cols-lg="2" class="star-required">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Description"
						                              	rules="required"                  
						                            > 
						                            	<b-form-textarea
						                            		v-model="detail.description"
						                                    placeholder="Description"
						                                    rows="4"
						                                    :disabled="isDisabled"
						                                    :formatter="length1000"
															style="padding-top: 12px !important"
						                                ></b-form-textarea>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
					                            </b-form-group>
					                            <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0 cb-aligncenter cb-aligncenter-y10">  
				                          		<b-form-group label="Services" label-cols-lg="2" class="star-required">
				                          			<validation-provider
						                              	#default="{ errors }"
						                              	name="Services"
						                              	rules="required"                  
						                            > 
						                          		<b-form-checkbox-group
														    v-model="selectedServices"
													        :options="optionsServices"
													        name="cb-services"
													        :disabled="isDisabled"
													        value-field="id"
    														text-field="name"
														>
														</b-form-checkbox-group>
														<small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
												</b-form-group>

				                          	</b-col>   
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>
			            <b-tab title="Sub-topics">
			            	<div v-if="this.$route.name == 'itees-product-detail-blank'">
				            	<div class="text-right topic-btn" style="z-index: 1; top: 0 ">
				            		<template v-if="(userData && permission.edit)">

							    		<b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click="addTopic">
						                    Add Sub Topic
						                </b-button>

						            </template>
						            <template v-else>
						            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
						                    Add Sub Topic
						                </b-button>
						            </template>
							    </div>
				            	<b-card class="px-table-0 pb-2 mt-10 nonshadow">
				            		<div class="text-right mb-2 mr-2" v-if="arr.length > 0">					                    
					                    <template v-if="collapseBool">
					                    	 <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click="ExpandAll">
						                        Expand All
						                    </b-button>
					                    </template>
					                    <template v-else>
					                    	<b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click="CollapseAll">
						                        Collapse All
						                    </b-button>
					                    </template>		

					                </div>
					                <b-card v-if="this.getSearch && arr.length < 1">
					                	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found
					                	</h4>
					                </b-card>
					                <b-card v-else-if="arr.length < 1">
					                	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
					                </b-card>
					                <div v-else class="accordion size-16" role="tablist">
					                	<template v-for="(item, index) in arr">
					                        <b-card no-body class="mb-1" :key="index">
					                            <div class="text-left button align-center d-flex" style="justify-content: space-between;">
					                                <span>
						                                <span class="mr-1">{{ item.name }}</span>
					                                    <template v-if="(userData && permission.edit)">
					                                      	<span class="px-05 cursor-pointer" @click="editTopic(item)">

									                                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">

									                                  	<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
									                                </svg>
							                              	</span>
							                              	<template v-if="userData && permission.edit">
								                                <span class="px-05 cursor-pointer" @click.stop="showDeleteAction(item.id, item.name)">
								                                  	<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								                                    	<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
								                                  	</svg>
								                                </span>
							                              	</template>
							                              	<template v-else>
								                                <span class="px-05">
								                                  	<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								                                    	<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
								                                  	</svg>
								                                </span>
							                              	</template>
							                            </template>
							                            <template v-else>
							                              	<span class="px-05">
								                                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								                                  <path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
								                                </svg>
							                              	</span>
							                              	<span class="px-05">
								                                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								                                  	<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
								                                </svg>
							                              	</span>
							                            </template>
					                           		</span>
						                            <span class="px-1" v-b-toggle="`collapse-${index+1}`" style="margin-right: -24px">
					                                    <feather-icon
							                              icon="ChevronDownIcon"
							                              size="24"
							                              color="#2E3A59" 
							                              class="when-closed" 
							                            />
							                            <feather-icon
							                              icon="ChevronUpIcon"
							                              size="24"
							                              color="#2E3A59" 
							                              class="when-opened" 
							                            />
						                            </span>
					                            </div>      
					                            <b-collapse :id="`collapse-${index+1}`" v-model="collapse[`item${(index+1)}`]">
					                                <b-card-text>{{ item.description }}</b-card-text>
					                            </b-collapse>
					                        </b-card>
					                    </template>
					                </div>
								</b-card>
							</div>
							<div v-else-if="this.$route.name == 'itees-product-addtopic'">
								<addTopic />
							</div>
							<div v-else-if="this.$route.name == 'itees-product-edittopic'">
								<editTopic ref="subTopic_label" />
							</div>
			            </b-tab>
			            <b-tab title="Documents" class="tab-child tab-pt-0">	            	
							<document ref="tab_2" :search2="getSearch" />
			            </b-tab> 
			        </b-tabs>   
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import addTopic from './topic/Add';
    import editTopic from './topic/Edit';
    import document from './document/List';

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	addTopic,
          	editTopic,
          	document,
	  	},
	  	data() {
		    return {
				name:"Product",
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "itees-product-detail-blank": "" },       
			    selectedServices: [],
		        optionsServices: [],

				isOpenCollapse: true,

		        detail: [],
		        isEditing: true,
		        isDisabled: true,
            	disableField: 'disabled-field',

            	arr: [],
		        collapse: {},
		        name_sublabel: {},

		        tabIndexProduct: 0,
		        getSearch: null,
		        timer: null,

		        collapseBool: true,

		        boolTrueAll: true,
		        boolFalseAll: false,
		        objectTam: {},
		        arrayTam: [],

		        accordion: {}
            }
		},	
		watch: {
			"$route": function(val) {
				if(val){
					if(val.name == 'itees-product-addtopic'){
						this.name_sublabel = { "itees-product-addtopic": "" }
						this.getProductName()
					}
					if(val.name == 'itees-product-edittopic'){
						this.labels = { "itees-product-edittopic": "" }						
						this.name_sublabel = { "itees-product-edittopic": "" }
						this.getProductName_1()
						this.getTopicName()
					}
					this.featchTopic(this.getSearch)
				}
			},
			getSearch(val) {  
                this.featchTopic(this.getSearch) 
                if(this.tabIndexProduct == 2 ){
                	this.loadDataToTab(val) 
                }
            },           
            tabIndexProduct: function(val) {
            	if(val == 2){
            		this.loadDataToTab(this.getSearch) 
            	}
            },
		},	
		mounted() {
            this.$nextTick(() => {
            	if(this.tabIndexProduct == 2 ){
            		this.loadDataToTab(this.getSearch)
            	}
            })
        },
		created(){
			this.featchItem()

			if(this.$route.name && this.$route.name == 'itees-product-addtopic' ){
				this.name_sublabel = { "itees-product-addtopic": "" }
				this.getProductName()
			}
			if(this.$route.name && this.$route.name == 'itees-product-edittopic' ){
				this.labels = { "itees-product-edittopic": "" }
				this.name_sublabel = { "itees-product-edittopic": "" }
				this.getProductName_1()
				this.getTopicName()
			}
			this.featchTopic(this.getSearch)
			this.fetchServicesSetup()
			this.checkPermission()
		},
		methods: {
			checkPermission(){
				var action = [];
				var namePage = this.name;
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
					
				})
				this.permission = action 
			},
			searchValue(val) {
				if (this.timer) {
			        clearTimeout(this.timer);
			        this.timer = null;		        
			    }
			    this.getSearch = val
			},
			loadDataToTab(val) {
                this.$refs[`tab_${this.tabIndexProduct}`].featctList(val)
            },
			length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
			getProductName(){
				this.$store
				.dispatch('project/detailProduct', {
					id: this.$route.params.id	
				})
				.then(response => {	
					this.name_sublabel['itees-product-addtopic'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getProductName_1(){
				this.$store
				.dispatch('project/detailProduct', {
					id: this.$route.params.id	
				})
				.then(response => {	
					this.name_sublabel['itees-product-edittopic'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getTopicName(){
	            this.$store
	            .dispatch('project/detailTopic', {
	              	id: this.$route.params.topic_id
	            })
	            .then(response => {
	            	this.labels['itees-product-edittopic'] = response.data.data.name
	            	console.log('topic labels: ', response.data.data.name)
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
			featchItem(){
	            this.$store
	            .dispatch('project/detailProduct', {
	              	id: this.$route.params.id  
	            })
	            .then(response => {
	              	this.detail = response.data.data

	              	this.selectedServices = this.detail.service.map(item => item.id )

	              	this.labels['itees-product-detail-blank'] = response.data.data.name

	              	// console.log('this.detail: ', this.detail)
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
	        fetchServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {
					filter: {
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 1
					    }
					  ]
					}
				})
				.then(response => {
					this.optionsServices = response.data.data
					// console.log('OptionsServices: ', this.optionsServices)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			editAction(){
				this.isDisabled = false
		        this.isEditing = false
		        this.disableField = ''
			},
			cancelAction(){
				this.$router.push({ name: 'itees-product' })
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		let datas = {
							name: this.detail.name,
							description: this.detail.description,
							service: this.selectedServices
						}
						// console.log('data: ', datas)
						this.$store
						.dispatch('project/editProduct', 
		                {
		                    id: this.$route.params.id,
		                    data: datas
		                })
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.detail.name + ' Updated!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.$router.push({ name: 'itees-product' })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
						})
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
                })
			},
	        ExpandAll() {
	            for (const [key, value] of Object.entries(this.collapse)) {
	                this.collapse[key] = true;
	            }
	            this.collapseBool = false
	        },
	        CollapseAll() {
	            for (const [key, value] of Object.entries(this.collapse)) {
	                this.collapse[key] = false;
	            }
	            this.collapseBool = true
	        },
	        addTopic(){
	        	this.$router.push(`/admin/itees-product/${this.$route.params.id}/add-sub-topic`)
	        },
	        editTopic(item){
	        	this.$router.push(`/admin/itees-product/${this.$route.params.id}/edit/${item.id}`)
	        },
	        featchTopic(search){
	        	this.$store
	            .dispatch('project/getSupTopic', {
	            	product_id: this.$route.params.id,
	            	search: search
	            })
	            .then(response => {
	            	this.arr = response.data.data
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
	        showDeleteAction(id, name){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
				  cancelVariant: 'outline-danger',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteTopic(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteTopic(id, title){
				this.$store
				.dispatch('project/deleteTopic', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.featchTopic(this.getSearch)	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},
		},
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";

	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>

</style>